import React from "react";
import Layout from "../components/layout";

export default function About() {
    return (
        <Layout>
            <h1>404</h1>
            <p>wut</p>
        </Layout>
    )
}